export const appealsConstants = {
	SET_RA_REGFORM             : "SET_RA_REGFORM",
	SET_DEFAULT_PAGE           : "SET_DEFAULT_PAGE",
	SET_RH                     : "SET_RH",
	SET_TH                     : "SET_TH",
	SET_FILTERS                : "SET_FILTERS",
	SET_FILTER                 : "SET_FILTER",
	SET_BACK_URL               : "SET_BACK_URL",
	CLEAR_ADDRESS_SEARCH_FIELD : "CLEAR_ADDRESS_SEARCH_FIELD",
	SET_TO_CLOSE_ADDRESS_FORM  : "SET_TO_CLOSE_ADDRESS_FORM",
	RegAppeal                  : {
		REQUEST : "REG_APPEAL_REQUEST",
		SUCCESS : "REG_APPEAL_SUCCESS",
		FAILURE : "REG_APPEAL_FAILURE"
	},
	RecallAppeal: {
		REQUEST : "RECALL_APPEAL_REQUEST",
		SUCCESS : "RECALL_APPEAL_SUCCESS",
		FAILURE : "RECALL_APPEAL_FAILURE",
		CLEAR   : "RECALL_APPEAL_CLEAR"
	},
	RedirectAppeal: {
		REQUEST : "REDIRECT_APPEAL_REQUEST",
		SUCCESS : "REDIRECT_APPEAL_SUCCESS",
		FAILURE : "REDIRECT_APPEAL_FAILURE",
		CLEAR   : "REDIRECT_APPEAL_CLEAR"
	},
	WCAppeal: {
		REQUEST : "WCAppeal_REQUEST",
		SUCCESS : "WCAppeal_SUCCESS",
		FAILURE : "WCAppeal_FAILURE",
		CLEAR   : "WCAppeal_CLEAR"
	},
	CreateAppeal: {
		REQUEST : "CREATE_APPEAL_REQUEST",
		SUCCESS : "CREATE_APPEAL_SUCCESS",
		FAILURE : "CREATE_APPEAL_FAILURE"
	},

	getListRA: {
		REQUEST       : "GET_LIST_RA_REQUEST",
		SUCCESS       : "GET_LIST_RA_SUCCESS",
		FAILURE       : "GET_LIST_RA_FAILURE",
		CLEAR_FILTER  : "CLEAR_FILTERS",
		UPDATE_FILTER : "UPDATE_FILTERS",
		CLEAR         : "GET_LIST_RA_CLEAR"
	},
	getListPrevAppeals: {
		REQUEST : "GET_LIST_PREV_REQUEST",
		SUCCESS : "GET_LIST_PREV_SUCCESS",
		FAILURE : "GET_LIST_PREV_FAILURE"
	},

	GetRedirectedFilesInfo: {
		REQUEST : "GET_REDIRECTED_FILE_INFO_REQUEST",
		SUCCESS : "GET_REDIRECTED_FILE_INFO_SUCCESS",
		FAILURE : "GET_REDIRECTED_FILE_INFO_FAILURE",
		CLEAR   : "GET_LIST_RA_CLEAR"
	},

	GetRedirectRAs: {
		REQUEST : "GET_REDIRECT_RAS_REQUEST",
		SUCCESS : "GET_REDIRECT_RAS_SUCCESS",
		FAILURE : "GET_REDIRECT_RAS_FAILURE"
	},

	ClearCreateResult: {
		CLEAR: "CLEAR_CREATE_RESULT"
	},
	GetListAppeal: {
		REQUEST : "GET_LIST_APPEAL_REQUEST",
		SUCCESS : "GET_LIST_APPEAL_SUCCESS",
		FAILURE : "GET_LIST_APPEAL_FAILURE"
	},
	GetReOrgAppealById: {
		REQUEST : "GET_RA_BY_ID_REQUEST",
		SUCCESS : "GET_RA_BY_ID_SUCCESS",
		FAILURE : "GET_RA_BY_ID_FAILURE"
	},
	GetAppealById: {
		REQUEST : "GET_APPEAL_BY_ID_REQUEST",
		SUCCESS : "GET_APPEAL_BY_ID_SUCCESS",
		FAILURE : "GET_APPEAL_BY_ID_FAILURE",
		CLEAR   : "GET_APPEAL_BY_ID_CLEAR"
	},
	GetListPrevAppealsForDisciplinaryActions: {
		REQUEST : "GET_LIST_APPEALS_FOR_DISCIPLINARY_ACTIONS_REQUEST",
		SUCCESS : "GET_LIST_APPEALS_FOR_DISCIPLINARY_ACTIONS_SUCCESS",
		FAILURE : "GET_LIST_APPEALS_FOR_DISCIPLINARY_ACTIONS_FAILURE"
	},
	GetMinReOrgAppealInfoByAppealId: {
		REQUEST : "GET_MIN_REORG_APPEAL_INFO_BY_APPEALID_REQUEST",
		SUCCESS : "GET_MIN_REORG_APPEAL_INFO_BY_APPEALID_SUCCESS",
		FAILURE : "GET_MIN_REORG_APPEAL_INFO_BY_APPEALID_FAILURE",
		CLEAR   : "GET_MIN_REORG_APPEAL_INFO_BY_APPEALID_CLEAR"
	},
	GetAppealFilesByAppealId: {
		REQUEST : "GET_APPEAL_FILES_BY_APPEALID_REQUEST",
		SUCCESS : "GET_APPEAL_FILES_BY_APPEALID_SUCCESS",
		FAILURE : "GET_APPEAL_FILES_BY_APPEALID_FAILURE",
		CLEAR   : "GET_APPEAL_FILES_BY_APPEALID_CLEAR"
	},
	DownloadAppealFile: {
		REQUEST : "DOWNLOAD_APPEAL_FILE_BY_ID_REQUEST",
		SUCCESS : "DOWNLOAD_APPEAL_FILE_BY_ID_SUCCESS",
		FAILURE : "DOWNLOAD_APPEAL_FILE_BY_ID_FAILURE"
	},
	DownloadAllAppealFiles: {
		REQUEST : "DOWNLOAD_ALL_APPEAL_FILES_BY_APPEAL_ID_REQUEST",
		SUCCESS : "DOWNLOAD_ALL_APPEAL_FILES_BY_APPEAL_ID_SUCCESS",
		FAILURE : "DOWNLOAD_ALL_APPEAL_FILES_BY_APPEAL_ID_FAILURE"
	},
	DownloadAppealAnswerFile: {
		REQUEST : "DOWNLOAD_APPEAL_FILE_BY_ID_REQUEST",
		SUCCESS : "DOWNLOAD_APPEAL_ANSWER_FILE_BY_ID_SUCCESS",
		FAILURE : "DOWNLOAD_APPEAL_FILE_BY_ID_FAILURE"
	},
	CheckPreviousAppeal: {
		REQUEST : "CHECK_PREV_APPEAL_REQUEST",
		SUCCESS : "CHECK_PREV_APPEAL_SUCCESS",
		FAILURE : "CHECK_PREV_APPEAL_FAILURE",
		CLEAR   : "CHECK_PREV_APPEAL_CLEAR"
	},
	CreateOrgAppeal: {
		REQUEST : "CREATE_ORG_APPEAL_REQUEST",
		SUCCESS : "CREATE_ORG_APPEAL_SUCCESS",
		FAILURE : "CREATE_ORG_APPEAL_FAILURE",
		CLEAR   : "CREATE_ORG_APPEAL_CLEAR"
	},

	EditOrgAppeal: {
		REQUEST : "EDIT_ORG_APPEAL_REQUEST",
		SUCCESS : "EDIT_ORG_APPEAL_SUCCESS",
		FAILURE : "EDIT_ORG_APPEAL_FAILURE",
		CLEAR   : "EDIT_ORG_APPEAL_CLEAR"
	},

	EditElectronicOrgAppeal: {
		REQUEST : "EDIT_ELECTRONIC_ORG_APPEAL_REQUEST",
		SUCCESS : "EDIT_ELECTRONIC_ORG_APPEAL_SUCCESS",
		FAILURE : "EDIT_ELECTRONIC_ORG_APPEAL_FAILURE",
		CLEAR   : "EDIT_ELECTRONIC_ORG_APPEAL_CLEAR"
	},

	FinaliseAppeal: {
		CLEAR   : "FINALISE_APPEAL_CLEAR",
		REQUEST : "FINALISE_APPEAL_REQUEST",
		SUCCESS : "FINALISE_APPEAL_SUCCESS",
		FAILURE : "FINALISE_APPEAL_FAILURE"
	},
	EditReviewProcess: {
		REQUEST : "EDIT_REVIEW_PROCESS_REQUEST",
		SUCCESS : "EDIT_REVIEW_PROCESS_SUCCESS",
		FAILURE : "EDIT_REVIEW_PROCESS_FAILURE"
	},
	GetAnswerFilesByAppealId: {
		REQUEST : "GET_ANSWER_FILES_BY_APPEAL_ID_REQUEST",
		SUCCESS : "GET_ANSWER_FILES_BY_APPEAL_ID_SUCCESS",
		FAILURE : "GET_ANSWER_FILES_BY_APPEAL_ID_FAILURE",
		CLEAR   : "GET_ANSWER_FILES_BY_APPEAL_ID_CLEAR"
	},
	GetAppealChangeHistory: {
		REQUEST : "GET_APPEAL_CHANGE_HISTORY_REQUEST",
		SUCCESS : "GET_APPEAL_CHANGE_HISTORY_SUCCESS",
		FAILURE : "GET_APPEAL_CHANGE_HISTORY_FAILURE"
	},
	DownloadAppealTransferTransmittalFile: {
		REQUEST : "DOWNLOAD_APPEAL_TRANSFER_TRANSMITTAL_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_APPEAL_TRANSFER_TRANSMITTAL_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_APPEAL_TRANSFER_TRANSMITTAL_FILE_FAILURE"
	},
	GetHeadViceOrg: {
		REQUEST : "GET_HEADVICE_ORG_REQUEST",
		SUCCESS : "GET_HEADVICE_ORG_SUCCESS",
		FAILURE : "GET_HEADVICE_ORG_FAILURE"
	},
	CreateRedirectAppealForm: {
		REQUEST : "CREATE_REDIRECT_APPEAL_FORM_REQUEST",
		SUCCESS : "CREATE_REDIRECT_APPEAL_FORM_SUCCESS",
		FAILURE : "CREATE_REDIRECT_APPEAL_FORM_FAILURE"
	},
	CreateAppealRequestForm: {
		REQUEST : "CREATE_APPEAL_REQUEST_FORM_REQUEST",
		SUCCESS : "CREATE_APPEAL_REQUEST_FORM_SUCCESS",
		FAILURE : "CREATE_APPEAL_REQUEST_FORM_FAILURE",
		CLEAR   : "CLEAR_APPEAL_REQUEST_FORM"
	},
	CreateAppealResponseForm: {
		REQUEST : "CREATE_APPEAL_RESPONSE_FORM_REQUEST",
		SUCCESS : "CREATE_APPEAL_RESPONSE_FORM_SUCCESS",
		FAILURE : "CREATE_APPEAL_RESPONSE_FORM_FAILURE"
	},
	GetAppealRequestForms: {
		REQUEST : "GET_APPEAL_REQUEST_FORMS_REQUEST",
		SUCCESS : "GET_APPEAL_REQUEST_FORMS_SUCCESS",
		FAILURE : "GET_APPEAL_REQUEST_FORMS_FAILURE"
	},
	GetAppealRequestFormsPaged: {
		REQUEST : "GET_APPEAL_REQUEST_FORMS_PAGED_REQUEST",
		SUCCESS : "GET_APPEAL_REQUEST_FORMS_PAGED_SUCCESS",
		FAILURE : "GET_APPEAL_REQUEST_FORMS_PAGED_FAILURE"
	},
	DownloadAppealRequestFormFile: {
		REQUEST : "DOWNLOAD_APPEAL_REQUEST_FORM_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_APPEAL_REQUEST_FORM_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_APPEAL_REQUEST_FORM_FILE_FAILURE"
	},
	ChangeRedirectAppealForm: {
		REQUEST : "CHANGE_REDIRECT_APPEAL_FORM_REQUEST",
		SUCCESS : "CHANGE_REDIRECT_APPEAL_FORM_SUCCESS",
		FAILURE : "CHANGE_REDIRECT_APPEAL_FORM_FAILURE"
	},
	GetRedirectAppealForms: {
		REQUEST : "GET_REDIRECT_APPEAL_FORMS_REQUEST",
		SUCCESS : "GET_REDIRECT_APPEAL_FORMS_SUCCESS",
		FAILURE : "GET_REDIRECT_APPEAL_FORMS_FAILURE"
	},
	RedirectAppealFromForm: {
		REQUEST : "REDIRECT_APPEAL_FROM_FORM_REQUEST",
		SUCCESS : "REDIRECT_APPEAL_FROM_FORM_SUCCESS",
		FAILURE : "REDIRECT_APPEAL_FROM_FORM_FAILURE",
		CLEAR   : "REDIRECT_APPEAL_FROM_FORM_CLEAR"
	},
	DownloadRedirectAppealFormFile: {
		REQUEST : "DOWNLOAD_REDIRECT_APPEAL_FORM_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_REDIRECT_APPEAL_FORM_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_REDIRECT_APPEAL_FORM_FILE_FAILURE"
	},
	ExportListToExcel: {
		REQUEST : "EXPORT_LIST_TO_EXCEL_REQUEST",
		SUCCESS : "EXPORT_LIST_TO_EXCEL_SUCCESS",
		FAILURE : "EXPORT_LIST_TO_EXCEL_FAILURE",
		СLEAR   : "EXPORT_LIST_TO_EXCEL_CLEAR"
	},
	GetRepeatAppeals: {
		REQUEST : "GET_REPEAT_APPEALS_REQUEST",
		SUCCESS : "GET_REPEAT_APPEALS_SUCCESS",
		FAILURE : "GET_REPEAT_APPEALS_FAILURE",
		СLEAR   : "GET_REPEAT_APPEALS_CLEAR"
	},
	TakeIntoAccount: {
		REQUEST : "TAKE_INTO_ACCOUNT_REQUEST",
		SUCCESS : "TAKE_INTO_ACCOUNT_SUCCESS",
		FAILURE : "TAKE_INTO_ACCOUNT_FAILURE",
		CLEAR   : "TAKE_INTO_ACCOUNT_CLEAR"
	},
	GetRecallByRegistratorFiles: {
		REQUEST : "GET_RECALL_BY_REGISTRATOR_REQUEST",
		SUCCESS : "GET_RECALL_BY_REGISTRATOR_SUCCESS",
		FAILURE : "GET_RECALL_BY_REGISTRATOR_FAILURE",
		CLEAR   : "GET_RECALL_BY_REGISTRATOR_CLEAR"
	},
	RecallByRegistrator: {
		REQUEST : "RECALL_BY_REGISTRATOR_REQUEST",
		SUCCESS : "RECALL_BY_REGISTRATOR_SUCCESS",
		FAILURE : "RECALL_BY_REGISTRATOR_FAILURE",
		CLEAR   : "RECALL_BY_REGISTRATOR_CLEAR"
	},
	DownloadRecallBasisFile: {
		REQUEST : "DOWNLOAD_RECALL_BASIS_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_RECALL_BASIS_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_RECALL_BASIS_FILE_FAILURE"
	},
	GetReOrgAppealConvertFile: {
		REQUEST : "GET_REORGAPPEAL_CONVERT_FILE_REQUEST",
		SUCCESS : "GET_REORGAPPEAL_CONVERT_FILE_SUCCESS",
		FAILURE : "GET_REORGAPPEAL_CONVERT_FILE_FAILURE",
		CLEAR  	: "GET_REORGAPPEAL_CONVERT_FILE_CLEAR"
	},
	GetRedirectAccompanyingLetterFile: {
		REQUEST : "GET_REDIRECT_ACCOMPANYING_LETTER_FILE_REQUEST",
		SUCCESS : "GET_REDIRECT_ACCOMPANYING_LETTER_FILE_SUCCESS",
		FAILURE : "GET_REDIRECT_ACCOMPANYING_LETTER_FILE_FAILURE",
		CLEAR  	: "GET_REDIRECT_ACCOMPANYING_LETTER_FILE_CLEAR"
	},
	GetPotentialOrgAppealTerritories: {
		REQUEST : "GET_POTENTIAL_ORG_APPEAL_TERRITORIES_REQUEST",
		SUCCESS : "GET_POTENTIAL_ORG_APPEAL_TERRITORIES_SUCCESS",
		FAILURE : "GET_POTENTIAL_ORG_APPEAL_TERRITORIES_FAILURE",
		CLEAR  	: "GET_POTENTIAL_ORG_APPEAL_TERRITORIES_CLEAR"
	},
	GetAllReOrgAppealTerritories: {
		REQUEST : "GET_All_REORG_APPEAL_TERRITORIES_REQUEST",
		SUCCESS : "GET_All_REORG_APPEAL_TERRITORIES_SUCCESS",
		FAILURE : "GET_All_REORG_APPEAL_TERRITORIES_FAILURE",
		CLEAR  	: "GET_All_REORG_APPEAL_TERRITORIES_CLEAR"
	},
	DownloadAdditionalOrderMessageFiles: {
		REQUEST : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILES_REQUEST",
		SUCCESS : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILES_SUCCESS",
		FAILURE : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILES_FAILURE",
		CLEAR   : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILES_CLEAR"
	},
	DownloadAdditionalOrderMessageFile: {
		REQUEST : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILE_FAILURE",
		CLEAR   : "DOWNLOAD_ADDITIONAL_ORDER_MESSAGE_FILE_CLEAR"
	},
	ClearFileUploadChange:  "CLEAR_FILE_UPLOAD_CHANGE_REQUEST",
}
