import {
	calendarConstants
}from './constants'
import notice from '../../../components/Notice'
import i18n from '../localization'
import isRu from '../../../helpers/isRu'

const initialState = {
	days        : null,
	daysLoading : false,
	daysError   : null,

	//changeDays;
	changeDaysLoading: false,
	daysWereChanged: false,

	//getOrgDays;
	orgDays        : null,
	orgDaysLoading : false,
	orgDaysError   : null,

	//changeOrgDays;
	changeOrgDaysLoading : false,
	daysChanged          : false,

	//getWorkSchedule;
	workSchedule        : null,
	workScheduleLoading : false,
	workScheduleError   : null,

	//changeWorkSchedule;
	changeWorkScheduleLoading: false,

	//getSpecificWorkSchedule;
	specificWorkSchedule        : null,
	specificWorkScheduleLoading : false,
	specificWorkScheduleError   : null,

	//changeSpecificWorkSchedule;
	сhangeSpecificWorkScheduleLoading : false,
	changeSpecificWorkScheduleSuccess : null
}

export default function (state = initialState, action){
	switch (action.type){
		//get work days
		case calendarConstants.GetWorkDays.REQUEST :
			return {
				...state,
				workDays    : null,
				daysLoading : true,
				daysError   : null
			}
		case calendarConstants.GetWorkDays.SUCCESS :
			return {
				...state,
				workDays    : action.payload.result,
				daysLoading : false,
				daysError   : null
			}
		case calendarConstants.GetWorkDays.FAILURE :
			return {
				...state,
				workDays    : null,
				daysLoading : false,
				daysError   : action.payload.err.data
			}

			//getDays;
		case calendarConstants.GetDays.REQUEST :
			return {
				...state,
				days        : null,
				daysLoading : true,
				daysError   : null
			}
		case calendarConstants.GetDays.SUCCESS :
			return {
				...state,
				days        : action.payload.result,
				daysLoading : false,
				daysError   : null
			}
		case calendarConstants.GetDays.FAILURE :
			return {
				...state,
				days        : null,
				daysLoading : false,
				daysError   : action.payload.err.data
			}

			//changeDays;
		case calendarConstants.ChangeCalendarDays.REQUEST :
			return {
				...state,
				changeDaysLoading: true,
				daysWereChanged: false
			}
		case calendarConstants.ChangeCalendarDays.SUCCESS :
			notice('success', i18n.calendarSuccessUpdated[isRu()])
			return {
				...state,
				changeDaysLoading: false,
				daysWereChanged: action.payload.result
			}
		case calendarConstants.ChangeCalendarDays.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				changeDaysLoading: false,
				daysWereChanged: false,
			}

			//getOrgDays;
		case calendarConstants.GetOrgDays.REQUEST :
			return {
				...state,
				orgDays        : null,
				orgDaysLoading : true,
				orgDaysError   : null
			}
		case calendarConstants.GetOrgDays.SUCCESS :
			return {
				...state,
				orgDays        : action.payload.result,
				orgDaysLoading : false,
				orgDaysError   : null
			}
		case calendarConstants.GetOrgDays.FAILURE :
			return {
				...state,
				orgDays        : null,
				orgDaysLoading : false,
				orgDaysError   : action.payload.err.data
			}

			//changeDays;
		case calendarConstants.ChangeOrgCalendarDays.REQUEST :
			return {
				...state,
				daysChanged          : false,
				changeOrgDaysLoading : true
			}
		case calendarConstants.ChangeOrgCalendarDays.SUCCESS :
			notice('success', i18n.calendarSuccessUpdated[isRu()])
			return {
				...state,
				daysChanged          : true,
				changeOrgDaysLoading : false
			}
		case calendarConstants.ChangeOrgCalendarDays.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				daysChanged          : false,
				changeOrgDaysLoading : false
			}

			//getWorkSchedule;
		case calendarConstants.GetWorkSchedule.REQUEST :
			return {
				...state,
				workSchedule        : null,
				workScheduleLoading : true,
				workScheduleError   : null
			}
		case calendarConstants.GetWorkSchedule.SUCCESS :
			return {
				...state,
				workSchedule        : action.payload.result,
				workScheduleLoading : false,
				workScheduleError   : null
			}
		case calendarConstants.GetWorkSchedule.FAILURE :
			return {
				...state,
				workSchedule         : null,
				workSchedulesLoading : false,
				workSchedulesError   : action.payload.err.data
			}

			//changeDays;
		case calendarConstants.ChangeWorkSchedule.REQUEST :
			return {
				...state,
				changeWorkScheduleLoading: true
			}
		case calendarConstants.ChangeWorkSchedule.SUCCESS :
			notice('success', i18n.workScheduleSuccessUpdated[isRu()])
			return {
				...state,
				changeWorkScheduleLoading: false
			}
		case calendarConstants.ChangeWorkSchedule.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				changeWorkScheduleLoading: false
			}

			//getWorkSchedule;
		case calendarConstants.GetSpecificWorkSchedule.REQUEST :
			return {
				...state,
				specificWorkSchedule        : null,
				specificWorkScheduleLoading : true,
				specificWorkScheduleError   : null
			}
		case calendarConstants.GetSpecificWorkSchedule.SUCCESS :
			return {
				...state,
				specificWorkSchedule        : action.payload.result,
				specificWorkScheduleLoading : false,
				specificWorkScheduleError   : null
			}
		case calendarConstants.GetSpecificWorkSchedule.FAILURE :
			return {
				...state,
				specificWorkSchedule         : null,
				specificWorkSchedulesLoading : false,
				specificWorkSchedulesError   : action.payload.err.data
			}

			//changeSpecificDays;
		case calendarConstants.ChangeSpecificWorkSchedule.REQUEST :
			return {
				...state,
				changeSpecificWorkScheduleLoading : true,
				changeSpecificWorkScheduleSuccess : null
			}
		case calendarConstants.ChangeSpecificWorkSchedule.SUCCESS :
			notice('success', i18n.specialWorkScheduleSuccessUpdated[isRu()])
			return {
				...state,
				changeSpecificWorkScheduleLoading : false,
				changeSpecificWorkScheduleSuccess : true
			}
		case calendarConstants.ChangeSpecificWorkSchedule.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				changeSpecificWorkScheduleLoading: false
			}

		default :
			return state
	}
}
