export const calendarConstants = {
  GetDays: {
    REQUEST: 'GET_CALENDAR_DAYS_REQUEST',
    SUCCESS: 'GET_CALENDAR_DAYS_SUCCESS',
    FAILURE: 'GET_CALENDAR_DAYS_FAILURE',
  },
  GetWorkDays: {
    REQUEST: 'GET_WORK_DAYS_REQUEST',
    SUCCESS: 'GET_WORK_DAYS_SUCCESS',
    FAILURE: 'GET_WORK_DAYS_FAILURE',
  },
  ChangeCalendarDays: {
    REQUEST: 'CHANGE_CALENDAR_DAYS_REQUEST',
    SUCCESS: 'CHANGE_CALENDAR_DAYS_SUCCESS',
    FAILURE: 'CHANGE_CALENDAR_DAYS_FAILURE',
  },
  GetOrgDays: {
    REQUEST: 'GET_ORG_CALENDAR_DAYS_REQUEST',
    SUCCESS: 'GET_ORG_CALENDAR_DAYS_SUCCESS',
    FAILURE: 'GET_ORG_CALENDAR_DAYS_FAILURE',
  },
  ChangeOrgCalendarDays: {
    REQUEST: 'CHANGE_ORG_CALENDAR_DAYS_REQUEST',
    SUCCESS: 'CHANGE_ORG_CALENDAR_DAYS_SUCCESS',
    FAILURE: 'CHANGE_ORG_CALENDAR_DAYS_FAILURE',
  },
  GetWorkSchedule: {
    REQUEST: 'GET_WORK_SHEDULE_REQUEST',
    SUCCESS: 'GET_WORK_SHEDULE_SUCCESS',
    FAILURE: 'GET_WORK_SHEDULE_FAILURE',
  },
  ChangeWorkSchedule: {
    REQUEST: 'CHANGE_WORK_SHEDULE_REQUEST',
    SUCCESS: 'CHANGE_WORK_SHEDULE_SUCCESS',
    FAILURE: 'CHANGE_WORK_SHEDULE_FAILURE',
  },
  GetSpecificWorkSchedule: {
    REQUEST: 'GET_SPECIFIC_WORK_SHEDULE_REQUEST',
    SUCCESS: 'GET_SPECIFIC_WORK_SHEDULE_SUCCESS',
    FAILURE: 'GET_SPECIFIC_WORK_SHEDULE_FAILURE',
  },
  ChangeSpecificWorkSchedule: {
    REQUEST: 'CHANGE_SPECIFIC_WORK_SHEDULE_REQUEST',
    SUCCESS: 'CHANGE_SPECIFIC_WORK_SHEDULE_SUCCESS',
    FAILURE: 'CHANGE_SPECIFIC_WORK_SHEDULE_FAILURE',
  }
}
