import { calendarConstants } from "./constants";

const initialState = {
	exceptDays          : null
}


export default function (state = initialState, action){
	switch (action.type){
			case calendarConstants.getExceptDays.REQUEST :
			return {
				...state,
				exceptDays: null
			}
		case calendarConstants.getExceptDays.SUCCESS :
			return {
				...state,
				exceptDays: action.payload.result
			}
		case calendarConstants.getExceptDays.FAILURE :
			return {
				...state,
				exceptDays: action.payload.err.data.errors
			}
		default :
			return state
	}
}
